import React from "react"
import { styled } from "@mui/material/styles"
import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js"
import featuresStyle from "./sectionStyle"
import PestanasCotizadores from "../PestanasCotizadores/PestanasCotizadores"

const NewDiv = styled("div")(({ theme, ownerState }) => {
  const { style } = ownerState
  return { ...featuresStyle[style] }
})

const NewH2 = styled("h2")(({ theme }) => ({
  ...featuresStyle.titleSection,
}))

export default function SectionCotizadoresMobile(props) {
  const { updateTitle } = props
  const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY

  const autoClasses = {
    ...featuresStyle.mlAuto,
    ...featuresStyle.mrAuto,
  }

  return (
    <div className="cd-section">
      <NewDiv
        ownerState={{
          style: "container",
        }}
      >
        <NewDiv
          ownerState={{
            style: "features1",
          }}
        >
          <GridContainer>
            <GridItem xs={12} sm={8} md={8} className={autoClasses} sx={{marginBottom: "4.6em"}}>
              <NewH2 style={{ marginBottom: "0" }}>
                {insuranceCompany == "OCEANICA"
                  ? "Cotiza con Nosotros"
                  : "Cotiza con Nosotros"}
              </NewH2>
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "8em",
            }}
          >
            <PestanasCotizadores
              updateTitle={updateTitle}
              insuranceCompany={insuranceCompany}
            />
          </GridContainer>
        </NewDiv>
      </NewDiv>
    </div>
  )
}
