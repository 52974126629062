const BudgetPetsTabFormStylesPublic = () => ({
    resetForm:{
      maxWidth: '95%',
      padding: 0,
      margin: 0,
      height: '260px',
      maxHeight: '260px'
    },
    button:{
      marginTop: '1em'
    },
    gridContainer:{
      width: '100%'
    },
    // controller:{
    //   height: '46.8px',
    // },
    inputUpperCase:{
      textTransform: "uppercase",
    },
  });
  
  export default BudgetPetsTabFormStylesPublic;