import axios from "axios";

const useBudgetPetsPublic = () => {
    const emitPetsBudgetPublic = async (params) => {
        try {
            let service = "/dbo/animals/gen_budget_public_pet";
            let response = await axios.post(service, params);
            if(response.status === 200 && response.data.p_budget_id) {
                return response.data;
            }
            else {
                return "error";
            }
        }
        catch(error) {
            console.log(error);
            return "error";
        }
    };
    return {
        emitPetsBudgetPublic
    };
};

export default useBudgetPetsPublic;