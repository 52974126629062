import React from 'react'

//mui components
import { styled } from "@mui/material/styles"

//Core components
import LandingPage from '../../Layout/LandingPage'
import SectionPrincipal from '../Sections/SectionPrincipal'
import SectionCaracteristicas from '../Sections/SectionCaracteristicas'
import SectionProductos from '../Sections/SectionProductos'
import SectionNoticias from '../Sections/SectionNoticias'
import SectionLocations from '../Sections/SectionLocations';
import presentationStyle from "./presentationStyle.js";
import PopUp from '../../Layout/Popup';
import PopUpAsesores from '../../Layout/PopUpAsesores.js'
import PopupadultooceanicaDesktop from '../../../../static/popup_producto_oceanica_bot_desktop.jpg'
import PopupadultooceanicaMobile from '../../../../static/popup_producto_oceanica_bot_mobile.jpg'
import PopupAutoSemanaSantaDesktop from '../../../../static/Popup_auto_semana_santa.jpg'
import PopupAutoSemanaSantaMobile from '../../../../static/popup_auto_semana_santa_mobile.jpg'

const company = process.env.GATSBY_INSURANCE_COMPANY


const NewDiv = styled("div")(({ theme }) => ({
    ...presentationStyle.main,
    ...presentationStyle.mainRaised
  }))

export default function PresentationPage() {

    React.useEffect(() => {
        document.body.scrollTop = 0;
    });
    return (
      <LandingPage
        noChatBot={true}
        pageTitle={
          company === "PIRAMIDE"
            ? "Empresa de seguros en Venezuela"
            : "Principal"
        }
      >
        {company === "OCEANICA" ? (
          <PopUpAsesores
            company={company}
            popupOceanica={PopupadultooceanicaMobile}
            popupOceanicaDesktop={PopupadultooceanicaDesktop}
            flyerOceanica="flyerRecaudosFacturacionalCobro.jpeg"
            callToActionMessage=""
          />
        ) : (
          <PopUpAsesores
            company={company}
            popupOceanica={PopupAutoSemanaSantaMobile}
            popupOceanicaDesktop={PopupAutoSemanaSantaDesktop}
            flyerOceanica="flyerRecaudosFacturacionalCobro.jpeg"
            callToActionMessage=""
          />
        )}

        <SectionPrincipal />
        <NewDiv>
          <SectionCaracteristicas />
          <SectionProductos />
          <SectionNoticias />
          <SectionLocations />
        </NewDiv>
      </LandingPage>
    )
}
