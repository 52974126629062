import React from "react"
import { navigate } from "gatsby"
import Axios from "axios"
import BudgetPinvestForm from "./BudgetPinvestForm"

export default function BudgetPinvestPublic({ codBroker, officeList }) {

  async function onGenerate(paramsForm) {
    try {
      const response = await Axios.post("/dbo/budgets/generate_budget_portal", {
        ...paramsForm,
        p_insurance_broker_code: null // advicer public when is null
      })
      navigate(`/cotizar_valida?id=${response.data.p_budget_id}`)
    } catch (error) {
      console.error(error)
    }
  }

  return <BudgetPinvestForm onGenerate={onGenerate} hiddenApplicant codBroker={codBroker} officeList={officeList}/>
}
