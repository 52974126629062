import React, { useState } from "react"
import { PatternFormat } from "react-number-format"

import Grid from "@mui/material/Grid"
import Tooltip from "@mui/material/Tooltip"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material//Select"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import Fab from "@mui/material/Fab"
import ClearIcon from "@mui/icons-material/Clear"

const BudgetPetInputPublic = (props) => {
  const {
    index,
    petNumber,
    petAge,
    petTypeAge,
    minPetAge,
    maxPetAge,
    errorPetAge,
    errorPetTypeAge,
    updatePetAge,
    updatePetTypeAge,
    deletePet,
    setErrorPetAge,
    setErrorPetTypeAge,
  } = props
  const [showTypeAge, setShowTypeAge] = useState(false)
  const handleChangePetAge = (e) => {
    let formattedValue = parseInt(e.target.value)
    let tester = parseInt(e.target.value.trim())
    if (errorPetAge === true) {
      setErrorPetAge(index, false)
    }
    if (e.target.value !== "") {
      if (formattedValue !== 0) {
        if (tester >= minPetAge && tester <= maxPetAge) {
          updatePetAge(index, e.target.value.trim())
        } else {
          updatePetAge(index, petAge)
        }
        setShowTypeAge(true)
      } else {
        updatePetAge(index, "")
        setShowTypeAge(false)
      }
    } else {
      updatePetAge(index, "")
      setShowTypeAge(false)
    }
  }
  const handleChangePetTypeAge = (e) => {
    if (errorPetTypeAge === true) {
      setErrorPetTypeAge(index, false)
    }
    if (e.target.value !== "") {
      updatePetTypeAge(index, e.target.value)
    } else {
      updatePetTypeAge(index, "")
    }
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          padding: "1rem 0 0.5rem 0",
          width: "100%",
        }}
      >
        <Grid item xs={12}>
          <div>
            <span
              style={{
                fontWeight: "bold",
                color: "#333333",
              }}
            >
              Mascota {petNumber + 1}
            </span>
          </div>
        </Grid>
        <Grid item xs={showTypeAge ? 5 : 10}>
          <PatternFormat
            label="Edad"
            customInput={TextField}
            variant="standard"
            allowNegative={false}
            format="##"
            value={petAge}
            placeholder="1"
            fullWidth
            onChange={handleChangePetAge}
            min="1"
            max="180"
            error={errorPetAge}
            helperText={errorPetAge ? "Debe especificar la edad" : null}
          />
        </Grid>
        {showTypeAge && (
          <Grid item xs={5}>
            <FormControl
              style={{
                width: "100%",
              }}
            >
              <InputLabel id={`p-pet-${petNumber}-typeage-label`}>
                En
              </InputLabel>
              <Select
                labelId={`p-pet-${petNumber}-typeage-label`}
                defaultValue=""
                value={petTypeAge}
                onChange={handleChangePetTypeAge}
                error={errorPetTypeAge}
                variant="standard"
              >
                <MenuItem value="month">
                  {petAge === "1" ? "Mes" : "Meses"}
                </MenuItem>
                <MenuItem value="year">
                  {petAge === "1" ? "Año" : "Años"}
                </MenuItem>
              </Select>
              {errorPetTypeAge && <FormHelperText>Error</FormHelperText>}
            </FormControl>
          </Grid>
        )}
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Tooltip title="Borrar mascota" aria-label="delete">
            <Fab
              sx={{
                background: "#fc2d22",
                color: "#ffffff",
                width: "35px",
                height: "35px",
              }}
              onClick={() => deletePet(index)}
            >
              <ClearIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}

export default BudgetPetInputPublic
