import React, { useEffect } from "react";
import { navigate } from "gatsby";

import BudgetPetsTabFormPublic from "./components/BudgetPetsTabFormPublic/BudgetPetsTabFormPublic"; 

import { useDialog } from "context/DialogContext";
import { useLoading } from "context/LoadingContext";

import useBudgetPetsPublic from "./services/useBudgetPetsPublic"; 

import { initAxiosInterceptors } from "utils/axiosConfig";

const BudgetPetsPublic = (props) => {
    const { codBroker, officeList, registerDataToHubsPot } = props;
    const dialog = useDialog();
    const loading = useLoading();
    const { emitPetsBudgetPublic } = useBudgetPetsPublic();
    const onGenerate = async (paramsForm) => {
        let response = await emitPetsBudgetPublic(paramsForm);
        await registerDataToHubsPot(paramsForm)
        if(response === "error") {
            dialog({
                variant: 'info',
                catchOnCancel: false,
                title: "Alerta",
                description: 'No se ha podido generar la cotización. Ha fallado el servicio /dbo/animals/gen_budget_public_pet. Por favor intente nuevamente.'
            });  
        }
        else {
            navigate(`/cotizar_valida?id=${response.p_budget_id}`);
        }
    };
    useEffect(() => {
        initAxiosInterceptors(dialog,loading);
    }, []);
    return(
        <>
            <BudgetPetsTabFormPublic 
                onGenerate={onGenerate}
                minPetAgeAllowed={3}
                maxPetAgeAllowed={180}
            />
        </>
    );
};

export default BudgetPetsPublic;
